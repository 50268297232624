<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="refreshTable()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <ViewItem ref="offer" v-if="true" v-model="showItem" :autoCall="autoCall" :offer="offerShow" @close="itemShowClose($event)" @work="openObject($event)" />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    ViewItem: () => import("./views/offerView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      m: this.$store.getters["config/get"].models.offer,
      model: this.$store.getters["config/get"].models.offer.list2,
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
      autoCall: false,
      offerShow: null,
      showItem: false,
      idItemShow: 0,
    };
  },
  computed: {
    url() {
      return this.m.url;
    },
  },
  created() {
    this.$root.title = "Предложения";
  },

  methods: {
    itemShowClose(d) {
      this.showItem = !true;

      this.loading = true;
      this.loading = !true;
      this.offerShow = null;
    },
    refreshTable() {
      this.$refs.table.updateData();
    },
    onClickRow(d) {
      let item = d.row;
      console.log("onClickRow", item);
      this.idItemShow = item.id;
      this.offerShow = item;
      this.autoCall = false;
      this.showItem = true;
      return;
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
